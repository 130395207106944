import React from "react";
import { HashRouter, createBrowserRouter, Navigate, Route, RouterProvider, Routes, BrowserRouter } from "react-router-dom";
import Main from "./Pages/main";
import Quick from "./Pages/quick";
import WFC from "./Pages/wfc";
import CV from "./Pages/cv";
import Error from "./Pages/error";


const App = () => {
  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/quick/*" element={<Quick />} />
      <Route path="/wfc/*" element={<WFC />} />
      <Route path="/wave-function-collapse/*" element={<WFC/>} />
      <Route path="/cv/*" element={<Navigate to={{pathname: "/resume"}} />} />
      <Route path="/resume/*" element={<CV />} />
      <Route path="*" element={<Navigate to={{pathname: "/"}} />} />
    </Routes>
  </BrowserRouter>;
};

export default App;
