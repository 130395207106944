import React from 'react'
import Card from './Card'
import Section from './Section'

export default function Experience() {
  return <Section id='experience'>
    <h3>Experience</h3>
    <article className='card'>
      <Card 
        image='/images/companies/quick.png'
        title='Quick'
        subtitle='Founder, CEO, Productivity Guru'
        content={<ul>
          <li>Rethinking productivity software.</li>
          <li>Check it out <a href='/quick'>here</a>.</li>
        </ul>}
        dates='2024-present'
      />
      <hr/>
      <Card 
        image='/images/companies/relaxad.jpeg'
        title='Relaxad/Honk and Go AB'
        subtitle='Software Engineer'
        content={<ul>
          <li>Doing full stack development at a start-up. Building a great product with a small team.</li>
          <li>Full Stack Engingeering Internships summer 2022 and 2023. After august 2023 I have been working part-time.</li>
        </ul>}
        dates='2022-present'
      />
      <hr/>
      <Card 
        image='/images/companies/kickflip.jpeg'
        title='Kickflip Games'
        subtitle='Founder, CEO, Software Engineer'
        content={<ul>
          <li>Develops 2D- and 3D mobile games for iOS and Android. </li>
          <li>The company has released two games so far, both of which have reached tens of thousands of users.</li>
        </ul>}
        dates='2020-present'
      />
      <hr/>
      <Card 
        image='/images/companies/kth.png'
        title='KTH Royal Institute of Technology'
        subtitle='Teaching Assistant'
        content={<ul>
          <li>Teacher Assistant in: <a href='https://www.kth.se/student/kurser/kurs/IS1500?l=en'>Computer Organization and Components 9.0 credits</a>.
          </li>
        </ul>}
        dates='2022-present'
      />
      <hr/>
      <Card 
        image='/images/companies/ersta.jpeg'
        title='Ersta Diakoni'
        subtitle='Software Engineer'
        content={<ul>
          <li>Helped develop various systems for the hospital, using a wide array of technologies. </li>
          <li>Projects include: hospital website, automation of tasks, rewriting of backend systems, tools for the department of finance, data processing and statistics. </li>
          <li>Most projects were done in either C++ or Python. </li>
        </ul>}
        dates='2020'
      />
    </article>
  </Section>  
}
