import React from 'react';
import Row from './Row.js';
import Ceneterd from './Centered.js';
import Footer from './Footer.js';
import Col from './Col.js';
import VerticalSpace from './VerticalSpace.js';

export default function Home() {
  return (
    <div>
      <h1>Final Results</h1>
      <p>In this project I:</p>
      <ul>
        <li>Implemented the default WFC algorithm</li>
        <li>Implemented the graph based WFC from the paper</li>
        <li>Replicated the baseline results from the paper</li>
        <li>Applied the graph version of the WFC to a new problem: 3D </li>
      </ul>
      <p>Applying the graph based WFC for 3D took some effort, but in the end I showed that the idea of the paper was applicable for this new usecase.</p>
      <h2>Results</h2>
      <p>In the 7 blog entries I had a three major milestones. These are listed below:</p>
      <h3>Simplest Possible 2D WFC</h3>
      <p><a href='/wfc/blog5'>In this blog</a>, I used a simple version of the 2D WFC and created the image below.</p>
      <Ceneterd><img src="/images/wfc/example4.png" alt="example4" width={400} /></Ceneterd>

      <h3>More Advanced 2D WFC</h3>
      <p><a href='/wfc/blog5'>In this blog</a>, I used a more advanced 2D version of the algorithm and created the image below.</p>
      <Ceneterd><img src="/images/wfc/example12.png" alt="example6" width={400} /></Ceneterd>

      <h3>Graph Based WFC for 3D</h3>
      <p><a href='/wfc/blog6'>In this blog</a>, I applied the graph based WFC on a 3D problem and generated the island below.</p>
      <Ceneterd><img src="/images/wfc/example15.png" alt="example6" width={400} /></Ceneterd>


      <h2>Demo</h2>
      <p>I created a demo where you can generate your own worlds using my implementation of the WFC algorithm. You can find it <a href="/wfc/demo">here</a>.</p>
      <Footer
        prevPost={"Blog 6: Graph Based WFC for 3D"}
        prevPostLink={"/wfc/blog6"}
        nextPost={"Demo"}
        nextPostLink={"/wfc/demo"}
      />
    </div >
  );
}
