// src/components/UnityLoader.js
import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

function Demo() {
  const { unityProvider } = useUnityContext({
    loaderUrl: "/unity/Build/Build.loader.js",
    dataUrl: "/unity/Build/Build.data",
    frameworkUrl: "/unity/Build/Build.framework.js",
    codeUrl: "/unity/Build/Build.wasm",
  });

  return <Unity
    style={{
      height: "100%",
      width: "100%",
    }}
    unityProvider={unityProvider}
  />;
}

export default Demo;