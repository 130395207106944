import React from 'react'
import './Card.scss'

export default function Card({
  image,
  title,
  subtitle,
  content,
  dates
}) {
  return (
    <>
      <div className='left'>
        {image &&
        <img src={image} alt={title} className='left'/>
        }
      </div>
      <div className='middle col'>
        <div className='hgroup'>
          <h4>{title}</h4>
          <h5>{subtitle}</h5>
          <h6>{dates}</h6>   
        </div>
        {content}
      </div>
      {/* <div className='right'>     
      </div> */}
    </>
  )
}