import React from 'react';
import VerticalSpace from './VerticalSpace.js';
import Row from './Row.js';
import Col from './Col.js';
import Footer from './Footer.js';

export default function Home() {
  return (
    <div>
      <h1>The Project</h1>
      <p>Hello!</p>
      <p>My name is Isak Reinholdsson and this is the first entry of my blog about the project I'm doing for the course DH2323.</p>
      <h2>Introduction</h2>
      <p>My starting point when trying to figure out what to do for this assignment was that I wanted to do something with procedural generation. That is, something about automatically generating content.</p>
      <p>Procedural generation is a very active research field, and there are many different algorithms and technologies that are used. One such algorithm that especially caught my interest was the wave function collapse algorithm.</p>
      <h2>Wave Function Collapse</h2>
      <p>The wave function collapse algorithm is a technique used for generating content based on various constraints. Inspired by quantum mechanics (hence the name), the algorithm uses a set of placement rules and some building blocks to create new content. I will not go deeper into it here, but hopefully after reading this blog, you will have a good understanding of how everything works.</p>
      <h2>Some Motivational Examples</h2>
      <ul>
        <li>The following talk by the brilliant Oskar Stålberg is a good introduction to the idea of WFC:</li>

        <iframe width="448" height="252" src="https://www.youtube.com/embed/0bcZb-SsnrA?si=eXvx-03S-4c3PC0e" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        <li>As far as I know: <a href="https://github.com/mxgmn/WaveFunctionCollapse">this</a> GitHub repo was the first to introduce the the whole idea of the algorithm. It is a very well written project that is worth checking out.</li>
        <li>I really liked this video as well for a more in depth explanation of some parts (it also features some mentions of Oskar Stålberg):</li>
        <iframe width="448" height="252" src="https://www.youtube.com/embed/2SuvO4Gi7uY?si=4PybJ1sbDSPLhVBN" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        <li><a href="https://ieeexplore-ieee-org.focus.lib.kth.se/document/8848019">This paper</a> is a more recent example of scientific research about the algorithm. It will be my starting point in this project.</li>
      </ul>
      <h2>What Exactly Will I Do?</h2>
      <p>The goal of this project will be to write my own implementation of the wave function collapse algorithm. To keep it scientifically relevant, I will implement a specific version of the algorithm introduced by Kim et al. in the paper <a href="https://ieeexplore-ieee-org.focus.lib.kth.se/document/8848019">Automatic Generation of Game Content using a Graph-based Wave Function Collapse Algorithm</a>. There, they describe a graph based approach for the problem. This paper will be used as my starting point and reference for doing the work. In the next blogpost I go into more detail of how it all works.</p>
      <Footer
        prevPost={"Home"}
        prevPostLink={"/wfc"}
        nextPost={"Blog 2: Simplest Possible 2D WFC"}
        nextPostLink={"/wfc/blog2"}
      />
    </div>
  );
}
