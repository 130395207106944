import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import Home from './Home';
import Blog1 from './Blog1';
import Blog2 from './Blog2';
import Blog3 from './Blog3';
import Blog4 from './Blog4';
import Blog5 from './Blog5';
import Blog6 from './Blog6';
import Blog7 from './Blog7';
import Demo from './Demo';

import './WaveFunctionCollapse.scss';
import { Route, Routes } from "react-router-dom";

function Blog() {
  console.log('blog')
  return <div className="WaveFunctionCollapse" style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}>
    <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '600px',
      padding: '20px',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h1><a style={{
            textDecoration: 'none',
            color: 'black',
          }} href="/wfc">Wave Function Collapse Project</a></h1>
          <h3>Blog by Isak Reinholdsson</h3>
        </div>
      </div>
      <hr />
      
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="blog1" element={<Blog1 />} />
        <Route path="/blog2" element={<Blog2 />} />
        <Route path="/blog3" element={<Blog3 />} />
        <Route path="/blog4" element={<Blog4 />} />
        <Route path="/blog5" element={<Blog5 />} />
        <Route path="/blog6" element={<Blog6 />} />
        <Route path="/blog7" element={<Blog7 />} />
        <Route path="*" element={<Home />} />
      </Routes>
      {/* <BlogEntry file='blog'/> */}
      {/* <Routes>
        <Route exact path="/wfc" element={}/ >
        <Route path="/wfc/:subpath" element={({ match }) => {
          const path = match.params.subpath;
          return <BlogEntry file={path} />
        }} />
      </Routes> */}
    </div>
  </div>
}

export default function WaveFunctionCollapse() {
  return (
    <div style={{ minWidth: '100%', minHeight: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
      <Routes>
        <Route path="/demo" element={<Demo />} />
        <Route path="/*" element={<Blog />} />
      </Routes>
    </div>
  );
}