import React from 'react';
import Row from './Row.js';
import Ceneterd from './Centered.js';
import Footer from './Footer.js';

export default function Home() {
  return (
    <div>
      <h1>Simplest Possible 2D WFC</h1>
      <p>Okay, time to get our hands dirty! In order to get a grip of how the algorithm works, my first step was to simply create a 2D version of the algorithm with pre defined constraints. This blog post will describe how I built this minimum viable version of the WFC algorithm.</p>

      <h2>Creating images</h2>
      <p></p>
      <p>The algorithm needs some building blocks to produce the final results. For our purposes, I created the following 3x3 pixel images as a starting point:</p>
      <Row style={{ justifyContent: 'space-between' }}>
        <img src="/images/wfc/0.png" alt="0" className='pixelated' width={80} />
        <img src="/images/wfc/1.png" alt="1" className='pixelated' width={80} />
        <img src="/images/wfc/2.png" alt="1" className='pixelated' width={80} />
        <img src="/images/wfc/3.png" alt="1" className='pixelated' width={80} />
        <img src="/images/wfc/4.png" alt="1" className='pixelated' width={80} />
      </Row>
      <p>My initial WFC will use these images is to create larger grid such that only the orange parts are connected to each other.</p>
      <h2>Generating a grid</h2>
      <p>We now want to implement an algorithm for producing larger images based on these building blocks. As a starting point I wrote a simple Unity Script to generate a 10x10 random grid with these images.</p>
      <Ceneterd><img src="/images/wfc/example1.png" alt="example1" width={400} /></Ceneterd>
      <p>While the results above look quite good, they are not sufficent for our purposes. There are many loose ends at various parts and we want all the pipes to connect.</p>
      <h2>The algorithm</h2>
      <p>The (non-graph) WFC algorithm is illustrated by Kim et al. in their <a href="https://ieeexplore-ieee-org.focus.lib.kth.se/document/8848019">paper</a> as follows:</p>
      <Ceneterd><img src="/images/wfc/wfc.png" alt="wfc" width={400} /></Ceneterd>
      <p>For this simple example we will not do any backtracking when things go wrong. Instead we will start the whole algorithm over. This is fine when the possibility space of solution is large, but will be a problem when we are working with a more constrained problem. I will implement backtracking later on.</p>
      <p>Other than that, the algorithm will be the same. We keep track of a 2D grid where each item is filled by a state class. In the beginning all tiles are set to be able to be in all possible states. We then choose the tile with the lowest entropy (explained below) and propagate the solution so that all tiles follows the constraints. We continue this loop until all tiles are collapsed. This provides a little bit of an implementaiton challenge, so please checkout the code for the project for how this is done. Below I explain the steps in more detail: </p>
      <h2>Adding constraints</h2>
      <p>The wave function collapse algorithm works by keeping all the possible states each position can be in. We call this a "superposition", which like the name of the algorithm is also inspired by quantum mechanics. During the algorithm, we "collapse" a superposition into a state and then turn that state </p>
      <p>To visualize this I started to display each square to contain either: the collapsed state it went to or a grey square containing the number of states it can be in.</p>

      <Ceneterd><img src="/images/wfc/example2.png" alt="example2" width={400} /></Ceneterd>
      <p>Initially, as no object has collapsed, we see that all 10x10 squares have 5 possible states to collapse into. At this point, the algorithm will collapse a random point.</p>
      <h2>Entropy</h2>
      <p>When collapsing a random point this we see that the number of possible states in the surrounding places decreases. This happens because our constraints prohibit the pipes to not connect (i.e. the two images with pipes pointing down but not up can not be stacked vertically). This means that the total number of choises is smaller in some squares than others. In the WFC this is considered the entropy. One can define entropy in multiple ways, but for our use cases we will consider the entropy to be equal to the number of possible states a square can collapse into. When picking the next state to collapse, the wave function will choose among the squares with the lowest entropy.</p>
      <Ceneterd><img src="/images/wfc/example3.png" alt="example3" width={400} /></Ceneterd>
      <h2>Propagation</h2>
      <p>If we continue running the algorithm and constrain the results in each iteration we finally get the following animation:</p>
      <Ceneterd><video controls src="/images/example5.mp4" alt="example5" width={400} autoPlay={true} loop={true} /></Ceneterd>

      <h2>Conclusion</h2>
      <p>The algorithm has successfully created a 10x10 grid where all the pipes are connected. Here is the final image: </p>
      <Ceneterd><img src="/images/wfc/example4.png" alt="example4" width={400} /></Ceneterd>
      <p>This is the simplest possible version of the WFC algorithm. In the next blog post, I will describe how we can make this more complicated.</p>
      <Footer
        prevPost={"Blog 1: The Project"}
        prevPostLink={"/wfc/blog1"}
        nextPost={"Blog 3: More Advanced 2D WFC"}
        nextPostLink={"/wfc/blog3"}
      />
    </div>
  );
}
