import React, { useEffect, useState } from 'react'
import Section from './Section'
import './VerticalCards.scss'
import VerticalCard from './VerticalCard'

export default function Projects() {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 800);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // const parallax = useParallax({
  //   translateY: [10, -20],
  // });

  const cards = [
    <VerticalCard         
      key={0}
      title='Quick'
      subtitle='My current full-time project'
      image={'/images/projects/quick.png'}
      dates='2024-present'
      content={<ul>
        <li><a href='https://apps.apple.com/us/app/quick-to-do-manager/id6642658926'>MVP iOS App now available</a>.</li>
        <li>Productivity software aimed at reinventing task management.</li>
        <li>Developed with React Native and the Reanimated/RNGH stack.</li>
      </ul>}
    />,    
    <VerticalCard  
      key={1}      
      title='Transformer from Scratch'
      subtitle=''
      dates='2024'
      content={<ul>
        <li>Together with some friends I implemented a transformer architecture from scratch.</li>
        <li>We used it for text generation and trained it on Wikipedia. The final model can autocomplete queries and produce high-quality text.</li>
        <li>Our <a href="https://github.com/IsakRein/transformer-textgen/blob/main/paper.pdf">paper</a>. </li>
        <li>Code is available <a href="https://github.com/IsakRein/transformer-textgen/blob/main/">here</a>.</li> 
        <li>We also implemented the following (from scratch)
          <ul>
            <li>A RNN.</li>
            <li>A LSTM.</li>
            <li>Various tokenization techniques like word2vec, BPE and BPE4.</li>
            <li>Nucleus Sampling and temperature based sampling.</li>
          </ul>
        </li>
      </ul>}
    />,
    <VerticalCard         
      key={2}      
      title='Wave Function Collapse Procedural Generation'
      image={'/images/wfc/example15.png'}
      dates='2024'
      content={<ul>
        <li>I developed a graph-based Wave Function Collapse algorithm for procedural generation of 3D tilemaps.</li>
        <li>I wrote a <a href='/wfc'>blog</a> about it.</li>
        <li>Generate your own random island with the online <a href='/wfc/demo'>demo</a>.</li>
      </ul>}
    />,
    <VerticalCard   
      key={10}      
      title='Traveling Salesperson Heuristics'
      dates='2023'
      content={<ul>
        <li>As part of the <a href='https://www.kth.se/student/kurser/kurs/DD2440?l=en'>Advanced Algorithms</a> course at KTH, my group developed and optimized various heuristics to solve the NP-complete Traveling Salesperson Problem (TSP).</li>
        <li>Our solution combined 2-opt, 3-opt, Clarke-Wright, and Lin-Kernighan heuristics, performing exceptionally well on benchmarks.</li>
        <li><a href='https://github.com/IsakRein/TSP'>Code</a></li>
      </ul>}
    />,

    <VerticalCard 
      key={6}        
      title='3D Renderer (Raytracer + Rasterizer)'
      dates='2023'
      image={'/images/projects/3d.bmp'}
      content={<ul>
        <li>As a fun side project, I used C++ to implement both a slower raytracer algorithm and a faster rasterizer to render 3D scenes. Support for lightning, shadows and multiple cameras.</li>
        <li>A few years ago, I also naively created a 3D renderer using 2D squares in Pygame. A demo of this fun but crazy idea can be viewed <a href='https://www.youtube.com/watch?v=g4IhuUIeygc'>here</a>.</li>
      </ul>}
    />, 
    <VerticalCard      
      key={7}   
      title='Face Generator'
      dates='2022'
      content={<ul>
        <li>Implemented face generation using simple Gaussian, improved Gaussian, and Gaussian Mixture Models</li>
        <li>Trained on the lfw dataset, producing some what convincing faces as shown below</li>
        <li>Code is available <a href="https://github.com/IsakRein/Portfolio-ML-and-AI/tree/main/FaceGenerator">here</a>.</li>
      </ul>}
      image={'/images/projects/faces.png'}
    />,
    <VerticalCard   
      key={11}      
      title='Video Generator'
      dates='2023-2024'
      content={<ul>
        <li>Experimenting with fully automated generation of short-form content for platforms like TikTok, Instagram, and YouTube using generative AI and web scraping.</li>
        <li>Currently a proof of concept, with potential for scaling. Some of my generated videos are available <a href='https://www.tiktok.com/@boogiemamaofficial'>here</a>.</li>
      </ul>}
    />,
    <VerticalCard    
      key={3}           
      title='Biden vs Trump'
      video={'/videos/biden_vs_trump.mov'}
      dates='2020'
      content={<ul>
        <li>Created a 2D platformer based on the 2020 U.S. presidential election.</li>
        <li>Gameplay combines elements of Mario and Geometry Dash.</li>
        <li>Reached thousands of players with very little marketing.</li>
        <li>Got on the app store top free action games list.</li>
      </ul>}
    />,
    <VerticalCard        
      key={5}       
      title='Reinforcement Learning for Stock Trading Bots'
      dates='2019-2023'
      content={<ul>
        <li>Web-scraped intraday trading data from the Swedish bank Avanza over several years.</li>
        <li>Used reinforcement learning to train trading bots using this data.</li>
      </ul>}
    />,
    <VerticalCard   
      key={8}      
      title='Algorithms & Competitive Programming'
      dates='2023-Present'
      content={<ul>
        <li>Recently I have been getting into competitive programming with 107 problems solved and a score of 439.4 on <a href='https://open.kattis.com/users/isakre'>my Kattis profile</a>.</li>
        <li>Also did the famous <a href='https://www.kth.se/student/kurser/kurs/DD2458?l=en'>Problem Solving and Programming under Pressure</a> course at KTH.</li>
      </ul>}
    />,
    <VerticalCard  
      key={9}       
      title='Multiplayer Tetris'
      dates='2021'
      video={'/videos/tetris.mov'}
      content={<ul>
        <li>Collaborated with a friend to create a simple online Tetris battle game for us to play.</li>
      </ul>}
    />,
    <VerticalCard
      key={4}         
      title='Piece Together'
      dates='2019'
      video={'/videos/piece_together.mp4'}
      videoStyle={{aspectRatio: 1}}
      content={<ul>
        <li>Created a unique 2D puzzle game to address the lack of quality options in the market.</li>
        <li>Reached thousands of players with very little marketing.</li>
      </ul>}
    />,


  ]

  const cardsLeft = cards.filter((_, i) => i % 2 === 0)
  const cardsRight = cards.filter((_, i) => i % 2 === 1)

  return <Section id={'projects'}>
    <h3>Projects</h3>
    <div className='vertical-cards'>
      <div className='col'>
        {cardsLeft}
        {!isWideScreen && cardsRight}
      </div>
      <div className='col'>
        {isWideScreen && cardsRight}
      </div>
    </div>
  </Section>
}