import React from 'react'

export default function Centered({ style, children }) {
  return <div style={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
    , ...style
  }}>
    {children}
  </div>
}