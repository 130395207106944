import React from 'react';
import { Route, Routes } from "react-router-dom";
import Privacy from './privacy';
import Main from './main';

export default function Quick() {
  return (
    <main>
      <header className='header'>
        <h2><mark>Quick</mark></h2>
      </header>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path='/privacy' element={<Privacy/>}/>
      </Routes>
    </main>)
}