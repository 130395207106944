import React, { useEffect, useState } from 'react'
import Header from './Header'
import Education from './Education'
import Experience from './Experience'
import Introduction from './Introduction'
import Projects from './Projects'

export default function Main() {
  const [currentHash, setCurrentHash] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const hashElements = document.querySelectorAll('.section');
      const scrollPosition = window.pageYOffset;

      hashElements.forEach((element) => {
        const distance = scrollPosition - element.offsetTop;
        const hash = element.getAttribute('id');

        if (distance < 30 && distance > -30 && currentHash !== hash) {
          window.history.pushState(hash, hash, `#${hash}`);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentHash]);
  return (
    <>
      <main>
        <Header/>
        <hr/>
        <Introduction/>
        <Experience/>
        <Education/>
        <Projects/>
      </main>
    </>
  )
}