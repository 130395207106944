import React from 'react'

export default function Main() {
  return (
    <>
      <h4>Reinventing task management</h4>
      <ul>
        <li><a href='https://apps.apple.com/us/app/quick-to-do-manager/id6642658926'>Download it here</a></li>
        <li><a href='/quick/privacy'>Privacy Policy</a></li>
      </ul>
    </>
  )
}