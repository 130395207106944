import React from 'react'
import VerticalSpace from './VerticalSpace'

export default function Home() {
  return (
    <div>
      <h3>Entries</h3>
      <VerticalSpace />
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>2024-05-01</td>
            <td><a href="/wfc/blog1">The Project</a></td>
          </tr>
          <tr>
            <td>2024-05-05</td>
            <td><a href="/wfc/blog2">Simplest Possible 2D WFC</a></td>
          </tr>
          <tr>
            <td>2024-05-10</td>
            <td><a href="/wfc/blog3">More Advanced 2D WFC</a></td>
          </tr>
          <tr>
            <td>2024-05-11</td>
            <td><a href="/wfc/blog4">The WFC of the Paper</a></td>
          </tr>
          <tr>
            <td>2024-05-11</td>
            <td><a href="/wfc/blog5">Replicating the Baseline Results from the Paper</a></td>
          </tr>
          <tr>
            <td>2024-05-15</td>
            <td><a href="/wfc/blog6">Graph Based WFC for 3D</a></td>
          </tr>
          <tr>
            <td>2024-05-20</td>
            <td><a href="/wfc/blog7">Final Results</a></td>
          </tr>
          <tr>
            <td>2024-05-20</td>
            <td><a href="/wfc/demo">Demo</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}