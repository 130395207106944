import React from 'react';

const Privacy = () => {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <p>
        This privacy policy applies to the Quick app ("Application") for mobile devices created by Kickflip Digital AB ("Service Provider") as a Freemium service. This service is intended for use "AS IS".
      </p>

      <h3>Information Collection and Use</h3>
      <p>
        The Application collects information when you download and use it. This may include:
      </p>
      <ul>
        <li>Your device's Internet Protocol address (e.g., IP address)</li>
        <li>The pages of the Application that you visit, the time and date of your visit, the time spent on those pages</li>
        <li>The time spent on the Application</li>
        <li>The operating system you use on your mobile device</li>
      </ul>
      <p>
        The Application does not gather precise information about the location of your mobile device.
      </p>
      <p>
        The Application collects your device's location to help the Service Provider determine your approximate geographical location and use it in the following ways:
      </p>
      <ul>
        <li>Geolocation Services: To provide features such as personalized content, relevant recommendations, and location-based services.</li>
        <li>Analytics and Improvements: Aggregated and anonymized location data helps analyze user behavior, identify trends, and improve the overall performance and functionality of the Application.</li>
        <li>Third-Party Services: Anonymized location data may be transmitted to external services to enhance the Application and optimize offerings.</li>
      </ul>
      <p>
        The Service Provider may use the information you provide to contact you with important information, required notices, and marketing promotions.
      </p>
      <p>
        For a better experience, the Service Provider may require you to provide personally identifiable information, which will be retained and used as described in this privacy policy.
      </p>

      <h3>Third-Party Access</h3>
      <p>
        Only aggregated, anonymized data is periodically transmitted to external services to aid in improving the Application and service. The Service Provider may share your information with third parties as described in this privacy statement.
      </p>
      <p>
        The Service Provider may disclose User Provided and Automatically Collected Information:
      </p>
      <ul>
        <li>As required by law, such as to comply with a subpoena or similar legal process;</li>
        <li>When they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
        <li>With trusted service providers who work on their behalf, do not have an independent use of the information disclosed to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
      </ul>

      <h3>Opt-Out Rights</h3>
      <p>
        You can stop all collection of information by the Application by uninstalling it using the standard uninstall processes available as part of your mobile device or via the mobile application marketplace or network.
      </p>

      <h3>Data Retention Policy</h3>
      <p>
        The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you'd like them to delete User Provided Data that you have provided via the Application, please contact them at isak.reinholdsson@gmail.com and they will respond in a reasonable time.
      </p>

      <h3>Children</h3>
      <p>
        The Service Provider does not knowingly solicit data from or market to children under the age of 13. They encourage parents and legal guardians to monitor their children's Internet usage and help enforce this Policy by instructing children not to provide personally identifiable information without permission. If you believe a child has provided personally identifiable information, please contact the Service Provider (isak.reinholdsson@gmail.com) so necessary actions can be taken.
      </p>

      <h3>Security</h3>
      <p>
        The Service Provider is concerned about safeguarding the confidentiality of your information and provides physical, electronic, and procedural safeguards to protect the information processed and maintained.
      </p>

      <h3>Changes</h3>
      <p>
        This Privacy Policy may be updated from time to time. The Service Provider will notify you of any changes by updating this page with the new Privacy Policy. Continued use of the Application is deemed approval of all changes.
      </p>
      <p>This privacy policy is effective as of 2024-08-17.</p>

      <h3>Your Consent</h3>
      <p>
        By using the Application, you consent to the processing of your information as set forth in this Privacy Policy now and as amended.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact the Service Provider via email at isak.reinholdsson@gmail.com.
      </p>
    </div>
  );
};

export default Privacy;