import React from 'react';
import Row from './Row.js';
import Ceneterd from './Centered.js';
import Footer from './Footer.js';
import Col from './Col.js';
import VerticalSpace from './VerticalSpace.js';

export default function Home() {
  return (
    <div>
      <h1>The WFC of the Paper</h1>
      <p>
                When initially introduced, the WFC worked by studying a sample images, creating tiles from it and derviving adjecency rules based on these tiles. This was a good start, but has two major limitations:
      </p>
      <ol>
        <li>Tiles are only affected by the four cells around it.</li>
        <li>A maximum of four constraints are possible per tile.</li>
      </ol>
      <p>
                The paper by Kim et al. <a href="https://ieeexplore-ieee-org.focus.lib.kth.se/document/8848019">Automatic Generation of Game Content using a Graph-based Wave Function Collapse Algorithm</a> describes a more sophisticated approach. The authors introduce a graph-based approach to the WFC problem. This allows for more complex constraints and a more expressive model. In this blog post, I will describe how to implement the WFC algorithm described in the paper.
      </p>
      <h2>Changes made</h2>
      <p>The previous implementation already implemented some necessary parts for the graph based WFC algorithm. For instance, we dynamically created constraints instead of looking at adjancencies in a sample image (we already solved problem #2 from above).</p>
      <p>However, to comply with the graph structure we implemented it so that a tile can have any number of neighbors in its adjecency list. The wfc is then propagated over this graph.</p>
      <h2>Results</h2>
      <p>These changes were enough to change our algorithm to a graph structure. In the next blogpost I replicate the baseline results from the paper.</p>
      <Footer
        prevPost={"Blog 3: More Advanced 2D WFC"}
        prevPostLink={"/wfc/blog3"}
        nextPost={"Blog 5: Replicating the Baseline Results from the Paper"}
        nextPostLink={"/wfc/blog5"}
      />
    </div >
  );
}
