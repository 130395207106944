import React from 'react';
import Row from './Row.js';
import Ceneterd from './Centered.js';
import Footer from './Footer.js';
import Col from './Col.js';
import VerticalSpace from './VerticalSpace.js';

export default function Home() {
  return (
    <div>
      <h1>Replicating the Baseline Results from the Paper</h1>
      <p>
                In the previous blogs post, we wrote a sophisticated WFC algorithm in accordance with the paper <a href="https://ieeexplore-ieee-org.focus.lib.kth.se/document/8848019">Automatic Generation of Game Content using a Graph-based Wave Function Collapse Algorithm</a> by Kim et al. The algorithm is now ready to be tested and compared to the results in the paper.
      </p>
      <h2>The result we are replicating</h2>
      <p>
                There exists an <a href="https://www.jstage.jst.go.jp/article/transinf/E103.D/8/E103.D_2019EDP7295/_pdf">extended version of the paper</a> where more interesting examples are shown. Specifically they show how the algorithm can be used for generating a pretty good looking illustration of a circuit board. This is the result we are aiming to replicate. Below is the image from the paper. We are trying to replicate the right image (we are using a uniform distribution).
        <Ceneterd><img src="/images/wfc/example10.png" alt="example6" width={400} /></Ceneterd>
      </p>

      <h2>The tiles</h2>
      <p>The following tiles were used as input for the algorithm. They are all 14x14 pixels and taken from <a href="https://github.com/mxgmn/WaveFunctionCollapse">this repo</a>. I assume the paper got them from the same place.</p>
      <Col>
        <Row style={{ justifyContent: 'space-between' }}>
          <img src="/images/wfc/bridge.png" alt="0" className='pixelated' width={80} />
          <img src="/images/wfc/component.png" alt="1" className='pixelated' width={80} />
          <img src="/images/wfc/connection.png" alt="1" className='pixelated' width={80} />
          <img src="/images/wfc/corner.png" alt="1" className='pixelated' width={80} />
          <img src="/images/wfc/dskew.png" alt="1" className='pixelated' width={80} />
        </Row>
        <VerticalSpace height={50} />
        <Row style={{ justifyContent: 'space-between' }}>
          <img src="/images/wfc/skew.png" alt="0" className='pixelated' width={80} />
          <img src="/images/wfc/substrate.png" alt="1" className='pixelated' width={80} />
          <img src="/images/wfc/t.png" alt="1" className='pixelated' width={80} />
          <img src="/images/wfc/track.png" alt="1" className='pixelated' width={80} />
          <img src="/images/wfc/transition.png" alt="1" className='pixelated' width={80} />
        </Row>
        <VerticalSpace height={50} />
        <Row style={{ justifyContent: 'space-between' }}>
          <img src="/images/wfc/turn.png" alt="0" className='pixelated' width={80} />
          <img src="/images/wfc/viad.png" alt="1" className='pixelated' width={80} />
          <img src="/images/wfc/vias.png" alt="1" className='pixelated' width={80} />
          <img src="/images/wfc/wire.png" alt="1" className='pixelated' width={80} />
          <div style={{ width: 80 }}></div>
        </Row>
      </Col>

      <h2>Results</h2>
      <p>Plugging in the tiles in our WFC, we get the following results (note that I add some delay at each step of the algorithm to make it easier to follow). The numbers in uninitialized tiles represent the entropy of that tile.</p>
      <Ceneterd><video controls src="/images/example11.mp4" alt="example5" width={400} autoPlay={true} loop={true} /></Ceneterd>
      <p>In case the video isn't working. This is one of the final images:</p>
      <Ceneterd><img src="/images/wfc/example12.png" alt="example6" width={400} /></Ceneterd>
      <p>To me the results look really good, and I would consider that we are done with the first of recreating the results from the paper. In the next blog, I use this sophisticated graph version of the problem on a new application: 3D.</p>
      <Footer
        prevPost={"Blog 4: The WFC of the Paper"}
        prevPostLink={"/wfc/blog4"}
        nextPost={"Blog 6: Graph Based WFC for 3D"}
        nextPostLink={"/wfc/blog6"}
      />
    </div >
  );
}
