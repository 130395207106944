import React from 'react'

export default function Section(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible((oldIsVisible) => {
        return entry.isIntersecting || oldIsVisible
      }));
    });
    observer.observe(domRef.current);
  }, []);
  return (
    <div
      className={`section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
      id={props.id}
    >
      {props.children}
    </div>
  );
}