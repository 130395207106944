import React from 'react';
import Row from './Row.js';
import Ceneterd from './Centered.js';
import Footer from './Footer.js';

export default function Home() {
  return (
    <div>
      <h1>More Advanced 2D WFC</h1>
      <p>
                In the previous blog post, we implemented the simples possible WFC. In this blog, I describe how to implement a more advanced version.
      </p>
      <h2>Automatically generating constraints</h2>
      <p>
                To get the first verison working, I manually added constraints in the code. This is obviously not scalable so we need to implement an improved system. The goal is for the algorithm to simply take in a set of images and automatically generate constraints using these.
      </p>
      <p>
                Checking if two tiles can be placed next to each other in a specific direction can be done in a lot of ways. Initially I had a naive version that checked connectivity by comparing all pixels in the relevant columns/rows for the various directions like in the image below.
      </p>
      <Ceneterd><img src="/images/wfc/example6.png" alt="example6" width={400} /></Ceneterd>
      <p>
                However this is not a good solution as it is too limiting. For instance in the second example below, we might want to allow the two pipes to connect their diagonal lines if they were to be placed beside eachother.
      </p>
      <Ceneterd><img src="/images/wfc/example7.png" alt="example6" width={400} /></Ceneterd>
      <p>
                To solve this i instead implemented a system that checks if the two images can be connected using specific socket points. This was inspired by the solution in <a href='https://oskarstalberg.com/game/wave/wave.html'>Oskar Stålberg's demo</a> where three socket points are used. For our usecase, the two end pixels and the middle pixels for a total of three sockets were used. This allowed for the images below to be connected.
      </p>
      <Ceneterd><img src="/images/wfc/example8.png" alt="example6" width={400} /></Ceneterd>


      <h2>Symmetry</h2>
      <p>
                A big part of generating interesting content using WFC, is having an expressive model. This can be achieved by having many options at each tile and by allowing as many connections as possible.
      </p>
      <p>
                A big part of this is supporting rotated and flipped images. This was implemented by simply modifying the inputed images to include all possible version of it. An important error that can occur here is that duplicates are created. This might not seen as a problem at first, but since the entropy in each tile is calculated by the number of optiomns. A tile where there actually exists just one possible tile can have a high entropy as each of the duplicates are counted as separate options. This was solved by simply adding a simple loop before adding the images to the model.
      </p>
      <p>
                In the end the model was able to use the below image as input and generate the following versions.
      </p>
      <Ceneterd><img src="/images/wfc/example9.png" alt="example6" width={400} /></Ceneterd>
      <h2>Where we are</h2>
      <p>We have now implemented a more sophisticated version of the WFC. The next step is to rewrite it using graphs like in the <a href="https://ieeexplore-ieee-org.focus.lib.kth.se/document/8848019">paper</a> by Kim et al.
      </p><Footer
        prevPost={"Blog 2: Simplest Possible 2D WFC"}
        prevPostLink={"/wfc/blog2"}
        nextPost={"Blog 4: The WFC of the Paper"}
        nextPostLink={"/wfc/blog4"}
      />
    </div >
  );
}
