import React from 'react'
import VerticalSpace from './VerticalSpace'
import Row from './Row'
import Col from './Col'

export default function Footer({ nextPost, prevPost, nextPostLink, prevPostLink }) {
  return (
    <Col>
      <VerticalSpace height={nextPost ? "50px" : "0"} />
      <hr />
      <Row style={{ justifyContent: 'space-between' }}>
        <Col style={{ width: '50%' }}>
          <h2>Prev post</h2>
          <a href={prevPostLink}>{prevPost}</a>
        </Col>
        <Col style={{ width: '50%', textAlign: 'end' }}>
          <h2>Next post</h2>
          <a href={nextPostLink}>{nextPost}</a>
        </Col>
      </Row>
      <VerticalSpace height={nextPost ? "100px" : "0"} />
    </Col>
  )
}