import React from 'react'
import Card from './Card'
import Section from './Section'

export default function Education() {
  return <Section id='education'>
    <h3>Education</h3>
    <article className='card'>
      <Card 
        image='/images/companies/eth.svg'
        title='ETH Zürich (Exchange M.Sc)'
        subtitle='Computer Science and Data Science'
        content={<ul>
          <li>Courses in Advanced Machine Learning, Probabilistic Artificial Intelligence, Principles of Macroeconomics among others.
          </li></ul>}
        dates='2024-2025'
      />
      <hr />
      <Card 
        image='/images/companies/kth.png'
        title='KTH Royal Institute of Technology (B.Sc + M.Sc + M.Eng)'
        subtitle='Computer Science and Data Science'
        content={<ul>
          <li>300 ECTS of courses in Mathematics, Computer Science and Machine Learning</li>
          <li>Bachelor thesis: <a href='https://kth.diva-portal.org/smash/record.jsf?dswid=-4164&pid=diva2%3A1797611&c=1&searchType=SIMPLE&language=sv&query=isak+reinholdsson&af=%5B%5D&aq=%5B%5B%5D%5D&aq2=%5B%5B%5D%5D&aqe=%5B%5D&noOfRows=50&sortOrder=author_sort_asc&sortOrder2=title_sort_asc&onlyFullText=false&sf=all'>Comparing Quantum Annealing and Simulated Annealing when Solving the Graph Coloring Problem</a>.
          </li>
        </ul>}
        dates='2020-2025'
      />
      <hr />
      <Card 
        image='/images/companies/su.png'
        title='Stockholm University (B.A)'
        subtitle='Business and Economics'
        content={<ul>
          <li>Courses in Finance, Accounting, Marketing and Organization.</li>
        </ul>}
        dates='2022-2025'
      />
    </article>
  </Section>
}
