import React from 'react';
import Row from './Row.js';
import Ceneterd from './Centered.js';
import Footer from './Footer.js';
import Col from './Col.js';
import VerticalSpace from './VerticalSpace.js';

export default function Home() {
  return (
    <div>
      <h1>Graph Based WFC for 3D</h1>
      <p>We have now implemented the WFC algorithm from the paper and replicated its base line results. We are now ready to build something on our own. The paper suggests sevaral applications of the graph approach, for instance solving sodoku and solving the 4 color theorem. However, an application that it does not mention that I thought would be interesting would be to use the graph based WFC on a 3D problem.</p>
      <p>Generating 3d using WFC has been done before, but as far as I can find only using the grid approach. That is, creating a sample scene and deriving constraints based on neigboring cells in the sample scene. This works, but has the same problems as discussed in <a href='/wfc/blog4'>blog 4</a>. In my solution I will show that the graph WFC is a good choice for generating 3d content. Below I write about the various steps I took.</p>
      <h2>Creating the input</h2>
      <p>Since this assignment is about the algorithm and not about 3d modeling, I decided to use an existing set of tiles from <a href="https://www.patreon.com/bolddunkley">Martin Donald</a>. These were 40 tiles to build an island, I then used blender to export these as 1x1x1 cubes. Below are some of the tiles:
        <Ceneterd><img src="/images/wfc/example14.png" alt="example6" width={400} /></Ceneterd>
      </p>
      <h2>Defining constraints</h2>
      <p>For the 3d problem, the input will be a list of meshes. In 2d we compared tiles by looking at a certain selection of pixels at the edges. We will use a similar approach for the meshes. This time, we have 6 sides to compare instead of 4. For each side we look at the mesh and the vertices of it that are on that edge. This results in 6 sockets.</p>
      <Ceneterd><img src="/images/wfc/example13.png" alt="example6" width={400} /></Ceneterd>
      <p>In our algorithm, we then generate these sockets for each tile and compare each tile with each other tile in each of the 6 directions. By doing this we get the edges of our graph (allowed neigbhbors) to do our WFC on. </p>
      <h2>Adding backtracking</h2>
      <p>Since the solution space is much smaller for these tiles, our naive approach of simply reseting resulted in a long time before a solution was found. To handle this I added backtracking. This is also suggested by the paper:</p>
      <Ceneterd><img src="/images/wfc/wfc.png" alt="example6" width={400} /></Ceneterd>
      <p>To implement this I simply saved the states of the 3D-grid after each iteration and pushed them to a stack. Whenever I encountered a contradiction, I popped the stack and redid my computations</p>
      <h2>Other issues</h2>
      <p>This part of the assignment took a lot of work to get working. While the WFC was already implemented, it was really hard getting everything right with the sockets and creating the edges of the graph for the WFC to use. I also had to make sure that the backtracking was implemented correctly. In the end, I got it working and the results are below:</p>
      <Ceneterd><img src="/images/wfc/example15.png" alt="example6" width={400} /></Ceneterd>
      <h2>Results</h2>
      <p>I have now done all the parts of the project. In the next blog I go through the results.</p>
      <Footer
        prevPost={"Blog 5: Replicating the Baseline Results from the Paper"}
        prevPostLink={"/wfc/blog5"}
        nextPost={"Blog 7: Final Results"}
        nextPostLink={"/wfc/blog7"}
      />
    </div >
  );
}
