import React from 'react'
import Section from './Section'

export default function Introduction() {
  return <Section id={'introduction'}>
    <h3>Introduction</h3>
    <p>Hello, my name is Isak Reinholdsson. I am currently rethinking productivity software with <a href='/quick'><b>Quick</b></a>. I am also studying in Zürich. 
    </p>
    <ul>
      {/* <li>For a quick summary of me, check out my <a href='/resume'><b>resumé</b></a>.</li>  */}
      <li>To get in touch with me, send me an email at <b>isakre [at] kth [dot] se</b>.</li>
      <li>Check out some of my <a href='#projects'>projects</a>!</li>
    </ul>
  </Section>
}