import React from 'react'
import ReactPlayer from 'react-player';

export default function VerticalCard({
  image,
  video,
  title,
  subtitle,
  content,
  dates,
  videoStyle = {}
}) {
  console.log({
    display: "flex",
    borderRadius: "10px",
    overflow: 'hidden',
    ...videoStyle
  })
  return (
    <article className='vertical-card'>
      <div className='hgroup'>
        <h4>{title}</h4>
        <h5>{subtitle}</h5>
        <h6>{dates}</h6>
      </div>
      {content}
      {(image || video) && <div style={{height: '25px'}}/>}
      {image && <img src={image} alt={title}/>}
      {video && <ReactPlayer
        url={video}
        playing={true}
        light={false}
        height={"auto"}
        width={"100%"}
        borderRadius={"10px"}
        muted={true}
        controls={false}
        loop={true}
        pip={false}
        playsinline={true}
        style={{
          display: "flex",
          borderRadius: "10px",
          overflow: 'hidden',
          ...videoStyle
        }}
      />}
    </article>
  )
}